import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

// Styles
import "./about-us.css";

// Components
import IndexLayout from "../layouts";
import Page from "../components/Page";
import StackedImage from "../components/StackedImage";

// Images and icons
import CurveBG from "../assets/images/curve-bg.svg";
import AboutSolutionBG from "../assets/images/about-solution-bg.png";
import StandardOSHA from "../assets/images/standard-osha.png";
import StandardISO from "../assets/images/standard-iso.png";
import StandardWHO from "../assets/images/standard-who.png";
import StandardUSPHS from "../assets/images/standard-usphs.png";
import AboutValueSolution from "../assets/images/about-value-solution.svg";
import AboutValueSupport from "../assets/images/about-value-support.svg";
import AboutValueFocus from "../assets/images/about-value-focus.svg";
import AboutValueHonesty from "../assets/images/about-value-honesty.svg";
import PeterOconnell from "../assets/images/about-peter-oconnell.jpg";

const SolutionsData = [
  {
    id: "SOL0001",
    title: "OSHA",
    image: StandardOSHA,
  },
  {
    id: "SOL0002",
    title: "ISO",
    image: StandardISO,
  },
  {
    id: "SOL0003",
    title: "WHO",
    image: StandardWHO,
  },
  {
    id: "SOL0004",
    title: "US Surgeon General",
    image: StandardUSPHS,
  },
];

const AboutValueData = [
  {
    id: "AV0001",
    image: AboutValueSolution,
    title: "Solutions",
    description:
      "Pointing out problems and blaming others is easy. Developing solutions is harder but this is what creates positive change.",
  },
  {
    id: "AV0002",
    image: AboutValueSupport,
    title: "Support",
    description:
      "Delivering world class support for all of our clients. Always aiming to devise the best solution to their challenge, regardless of what that means for us.",
  },
  {
    id: "AV0003",
    image: AboutValueFocus,
    title: "Focus",
    description:
      "Prioritising the right things to work on and focusing solely on those tasks until they are done to the best of our ability.",
  },
  {
    id: "AV0004",
    image: AboutValueHonesty,
    title: "Honesty",
    description:
      "Being honest about deadlines, our capacity to support clients and not overpromising to clients.",
  },
];

const META_TAGS = {
  title: "Achieve continuous psychosocial compliance",
  description:
    "Skodel helps busy people and safety leaders meet psychosocial requirements without disrupting other priorities.",
};

const AboutUs = () => {
  const [solutions, setSolutions] = useState<
    { id: string; title: string; image: string }[]
  >([]);
  const [aboutValue, setAboutValue] = useState<
    { id: string; image: string; title: string; description: string }[]
  >([]);

  useEffect(() => {
    setSolutions(SolutionsData);
    setAboutValue(AboutValueData);
  }, [setSolutions, setAboutValue]);

  return (
    <IndexLayout withBlackLogo={true} workplacesHeader={false}>
      <Helmet>
        {/*  Primary Meta Tags */}
        <title>{META_TAGS.title}</title>
        <meta name="title" content={META_TAGS.title} />
        <meta name="description" content={META_TAGS.description} />

        {/*  Open Graph / Facebook  */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://skodel.com/about-us" />
        <meta property="og:title" content={META_TAGS.title} />
        <meta property="og:description" content={META_TAGS.description} />

        {/*  Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://skodel.com/about-us" />
        <meta property="twitter:title" content={META_TAGS.title} />
        <meta property="twitter:description" content={META_TAGS.description} />
      </Helmet>
      <Page>
        <section className="section-about">
          <div
            className="section-bg"
            style={{ backgroundImage: `url('${CurveBG}')` }}
          ></div>
          <div className="main-container">
            <div className="about-hero">
              <h1>Get to know Skodel</h1>
              <p>
                Our mission is to create psychologically safer places to work,
                learn and grow.
              </p>
              <div className="home-hero-button">
                <a
                  href="https://www.youtube.com/watch?v=EjNXegR3OKo"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-white"
                >
                  <span>About Skodel</span>
                </a>
              </div>
            </div>
            <div className="about-wrapper">
              <h2>Hi, we are Skodel</h2>
              <p>
                There's a lot of pressure on organisations to meet psychosocial
                safety requirements. On one side, you need to meet these
                requirements. On the other, there's your core business. This
                creates a resource tradeoff. Skodel eliminates this tradeoff.
                Our observation was that organisations were either overwhelmed
                by unsustainable processes, unsure of what to do or they were
                avoiding it altogether. There was no simple way to meet
                requirements and not disrupt other key priorities. We worked
                with regulators and psychologists to develop the clearest and
                most efficient approach to meeting psychosocial safety
                requirements in line with legislation and international best
                practice. With Skodel, you don't have to be confused by
                compliance ever again. And, you don’t have to choose between
                meeting psychosocial safety requirements or other key
                priorities. By eliminating this need to choose, we empower more
                organisations to create safer places to work, learn and grow.
              </p>
            </div>
            <div className="about-content-wrapper">
              <div className="about-solution">
                <img src={AboutSolutionBG} alt={"Skodel"} />
                <h3>Our solutions are guided by best practice</h3>
                <div className="about-solution-wrapper">
                  {solutions?.map((item, index) => (
                    <div
                      className="about-solution-item"
                      key={item?.id + "-" + index}
                    >
                      <img src={item?.image} alt={item?.title} />
                      <h4>{item?.title}</h4>
                    </div>
                  ))}
                </div>
              </div>
              <div className="about-value">
                <div className="section-title text-center">
                  <h2>Values we live by</h2>
                  <p>
                    Whilst we will always strive to evolve as an organisation
                    and individuals, our core values remain the same.
                  </p>
                </div>
                <div className="about-value-wrapper">
                  {aboutValue?.map((item, index) => (
                    <div
                      className="about-value-card"
                      key={item?.id + "-" + index}
                    >
                      <img src={item?.image} alt={"Skodel"} />
                      <div className="about-value-description">
                        <h3>{item?.title}</h3>
                        <p>{item?.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="stacked-content">
                <StackedImage
                  image={PeterOconnell}
                  title={"Why I made the investment"}
                  description={
                    "I made the investment early in Skodel because of growing mental health concerns in society. They are a well led team from a mental health and governance standpoint, and the foundations of their product were supported by the best in the business when it comes to solutions-focused psychology. This is what organisations and people need. Solutions to the mental health challenges they face. Without that in place, leaders will always hesitate to address these issues head on for fear of not being able to safely address these."
                  }
                  author={"Peter O'Connell"}
                />
              </div>
            </div>
          </div>
        </section>
      </Page>
    </IndexLayout>
  );
};

export default AboutUs;
